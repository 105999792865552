import { productApi } from '../../features/product/query'
import { cmsApi } from '../../features/cms/query'
import { seoApi } from '../../features/seo/query'
import { orderApi } from '../../features/order/query'
import {
  ApiReducerState,
  ConfirmationReducerState,
  ContextReducerState,
  ContractReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  OrganizationReducerState,
  SearchReducerState,
  ThemeReducerState,
  UserReducerState,
} from './reducerStateInterface'
import account, { IAccountState } from '../../features/account'
import preview, { PreviewState } from '../../features/preview/slice'
import success, { ISuccessSliceState } from '../../features/success/slice'
import wishList, { WishListState } from '../../features/wishList/slice'
import plp, { PlpStateInterface } from '../../features/plp/slice'
import stores, { StoresState } from '../../features/stores/slice'
import physicalStores from '../../features/physicalStores/slice'
import product, {
  ProductApiState,
  ProductState,
} from '../../features/product/slice'
import { SeoStateInterface } from '../../features/seo/slice'
import ui, { IUiState } from '../../features/ui/slice'

import { IOrderSliceState } from '../../features/order/IOrderSliceState'

//Standard libraries
import { combineReducers } from 'redux'
import confirmation from './confirmation'
import api from './api'
import error from './error'
import order, { OrderApiState } from '../../features/order/slice'
import { PrescriptionSliceState } from '../../features/prescription/PrescriptionSliceState'
import prescription from '../../features/prescription/slice'
import orderDetails from './orderDetails'
import organization from './organization'
import search from './search'
import site, { SiteApiState, SiteReducerState } from '../../features/site/slice'
import theme from './theme'
import user from './user'
import { plpApi } from '../../features/plp/query'
import { categoryApi } from '../../features/category/query'
import cms, { ICmsState } from '../../features/cms/slice'
import { accountApi } from 'src/features/account/query'
import { tabApi } from '../../features/stores/query'
import { vmApi } from '../../features/virtualMirror/query'
import { physicalStoreApi } from '../../features/physicalStores/query'
import { PhysicalStore } from '@utils/shipToStoreUtils'
import { siteApi } from '../../features/site/query'
import {
  userSegmentsApi,
  UserSegmentsApiState,
} from '../../features/userSegments/query'
import userSegments from '../../features/userSegments/slice'
import { UserSegmentsState } from '../../features/userSegments/slice'
import { contextApi } from '../../features/context/query'
import { contractApi } from '../../features/contract/query'
import contract from '../../features/contract/slice'
import context from '../../features/context/slice'
import { elasticSearchApi } from '../../features/elasticSearchApi'
export * from './reducerStateInterface'

export interface RootReducerState {
  account: IAccountState
  api: ApiReducerState
  user: UserReducerState
  order: IOrderSliceState
  orderApi: OrderApiState
  error: ErrorReducerState
  seo: SeoStateInterface
  contract: ContractReducerState
  search: SearchReducerState
  organization: OrganizationReducerState
  context: ContextReducerState
  success: ISuccessSliceState
  confirmation: ConfirmationReducerState
  orderDetails: OrderDetailsReducerState
  orderDetailsLoadings: { [orderId: string]: boolean }
  theme: ThemeReducerState
  site: SiteReducerState
  wishList: WishListState
  preview: PreviewState
  product: ProductState
  cms: ICmsState
  productApi: ProductApiState
  plp: PlpStateInterface
  ui: IUiState
  prescription: PrescriptionSliceState
  stores: StoresState
  physicalStores: PhysicalStore
  siteApi: SiteApiState
  userSegments: UserSegmentsState
  userSegmentsApi: UserSegmentsApiState
}

const reducers = {
  account,
  api,
  user,
  order,
  error,
  search,
  context,
  organization,
  success,
  confirmation,
  orderDetails,
  theme,
  site,
  wishList,
  preview,
  [productApi.reducerPath]: productApi.reducer,
  product,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [seoApi.reducerPath]: seoApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [plpApi.reducerPath]: plpApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [tabApi.reducerPath]: tabApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [vmApi.reducerPath]: vmApi.reducer,
  [physicalStoreApi.reducerPath]: physicalStoreApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [userSegmentsApi.reducerPath]: userSegmentsApi.reducer,
  [contractApi.reducerPath]: contractApi.reducer,
  [contextApi.reducerPath]: contextApi.reducer,
  [elasticSearchApi.reducerPath]: elasticSearchApi.reducer,
  userSegments,
  contract,
  plp,
  ui,
  cms,
  prescription,
  stores,
  physicalStores,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
