//Standard libraries
import { Action, compose } from 'redux'
import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
//Redux
import rootReducer from '../reducers/index'
import { persistReducers, loadState } from '../../localStorage'
import { productApi } from '../../features/product/query'
import { cmsApi } from '../../features/cms/query'
import { seoApi } from '../../features/seo/query'
import { categoryApi } from '../../features/category/query'
import { plpApi } from '../../features/plp/query'
import { orderApi } from '../../features/order/query'
import { accountApi } from 'src/features/account/query'
import { tabApi } from 'src/features/stores/query'
import { vmApi } from '../../features/virtualMirror/query'
import { physicalStoreApi } from '../../features/physicalStores/query'
import { siteApi } from '../../features/site/query'
import listenerMiddleware from '../listener'
import rootSaga from '../sagas'
import { userSegmentsApi } from '../../features/userSegments/query'
import { contextApi } from '../../features/context/query'
import { contractApi } from '../../features/contract/query'
import { elasticSearchApi } from '../../features/elasticSearchApi'

const persistedState = loadState()
const sagaMiddleware = createSagaMiddleware()
const devTools =
  (process.env.NODE_ENV !== 'production' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
      thunk: true,
    })
      .prepend(listenerMiddleware.middleware)
      .concat(
        productApi.middleware,
        cmsApi.middleware,
        seoApi.middleware,
        categoryApi.middleware,
        plpApi.middleware,
        orderApi.middleware,
        accountApi.middleware,
        tabApi.middleware,
        vmApi.middleware,
        physicalStoreApi.middleware,
        siteApi.middleware,
        contractApi.middleware,
        contextApi.middleware,
        userSegmentsApi.middleware,
        elasticSearchApi.middleware,
        sagaMiddleware
      ),
  devTools,
  preloadedState: persistedState,
  enhancers: [],
})

sagaMiddleware.run(rootSaga)

export type AppStore = typeof store
export type AppState = ReturnType<typeof rootReducer>
export type AppReducerState = Parameters<typeof rootReducer>[0]
export type AppDispatch = typeof store.dispatch

type ExtraProps = {}

export type AppPayloadWithWidget<T = ExtraProps> = { widget: string } & T
export type AppPayloadAction<
  P = ExtraProps,
  T extends string = string
> = PayloadAction<P, T>
export type AppResponseAction<R = ExtraProps, T = string> = Action<T> & {
  response: R
}
export type AppPayloadWithWidgetAction<
  P = ExtraProps,
  T extends string = string
> = AppPayloadAction<AppPayloadWithWidget<P>, T>

persistReducers(store)

export default store
