import {
  ProductAlgolia,
  FacetsAlgolia,
} from '../../types/ProductAlgolia/ProductAlgolia'
import { ATTRIBUTES_TRANSLATED } from '../../constants/productAlgolia'
import {
  isAccessories,
  isCLAccessories,
  isContactLenses,
  isElectronics,
  isFrames,
  isOptical,
  isSun,
} from '@utils/product'
import { OrderItem } from '../../types/order'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import { PRODUCT_SOLDOUT_STATUS } from '../../constants/product'
import {
  ProductSoldOutStatus,
  ServerProductAttribute,
} from '../../types/product'

class ProductAlgoliaUtils {
  getFormattedProductAlgolia(results, country): ProductAlgolia[] {
    return results.map((result) => {
      if (result.hits) {
        const createClusters = result.hits.map((hit) => ({
          ...hit,
          prices: ProductPriceAlgoliaUtils.getDeterminatePrice(
            hit.prices,
            country
          ),
          attachments: this.getTransformAttachmentsToImage(hit.attachments),
        }))
        const firstProduct = result.hits.at(0)
        return {
          partnumberId: firstProduct.partnumberId,
          productId: firstProduct.productId,
          clusters: createClusters,
        }
      } else {
        return {
          partnumberId: result.partnumberId,
          productId: result.productId,
          clusters: [
            {
              ...result,
              prices: ProductPriceAlgoliaUtils.getDeterminatePrice(
                result.prices,
                country
              ),
              attachments: this.getTransformAttachmentsToImage(
                result.attachments
              ),
            },
          ],
        }
      }
    })
  }

  getTransformAttachmentsToImage(attachments): ProductAlgolia['attachments'] {
    return attachments
      ? attachments.map(({ id, identifier, name, rule, url, sequence }) => ({
          attachementAssetID: id,
          identifier,
          name,
          usage: rule,
          attachmentAssetPathRaw: url,
          sequence: sequence.toString(),
        }))
      : null
  }

  getAttributesFromUrl(parameters) {
    const keys = Object.keys(parameters)
    const attributes = {}
    keys.forEach((key) => {
      if (key.startsWith(ATTRIBUTES_TRANSLATED)) {
        attributes[key] = Array.isArray(parameters[key])
          ? parameters[key]
          : [parameters[key]]
      }
    })

    for (const facet in attributes) {
      attributes[facet] = attributes[facet].map((el: string) =>
        decodeURIComponent(el.replace(/\+/g, ' '))
      )
    }

    return attributes
  }

  getLxProductTypes = (lxProductType: string) => {
    return {
      //use LX_SEARCH_PAGE_PRODUCT_TYPE for CL , contact lenses and frames
      isFrames: isFrames(lxProductType), //include sun , optical and electronics
      isContactLenses: isContactLenses(lxProductType),
      isContactLensesAccessories: isCLAccessories(lxProductType),
      isAccessories: isAccessories(lxProductType),
      //use PRODUCT_TYPE for optical , sun and electronics
      isSun: isSun(lxProductType),
      isOptical: isOptical(lxProductType),
      isElectronics: isElectronics(lxProductType),
    }
  }

  getProductAttributes(attributes: ProductAlgolia['attributes_translated']) {
    // TODO: Add missing attributes
    const lxSearchPageProductType =
      attributes && attributes['LX_SEARCH_PAGE_PRODUCT_TYPE']
    const productType = attributes && attributes['PRODUCT_TYPE']
    const frameShape = attributes && attributes['FRAME_SHAPE']
    const frameMaterialFacet = attributes && attributes['FRAME_MATERIAL_FACET']
    const frameMaterialClass = attributes && attributes['FRAME_MATERIAL_CLASS']
    const templeColorFacet = attributes && attributes['TEMPLE_COLOR_FACET']
    const frontColorFacet = attributes && attributes['FRONT_COLOR_FACET']
    const colorCode = attributes && attributes['COLOR_CODE']
    const poralized = attributes && attributes['POLARIZED']
    const isMostPopular = attributes && attributes['LX_IS_MOST_POPULAR']
    const roxable = attributes && attributes['ROXABLE']
    const sustainabilityClaim = attributes && attributes['SUSTAINABILITY_CLAIM']
    const customizable = attributes && attributes['CUSTOMIZABLE']
    const webExclusive = attributes && attributes['WEB EXCLUSIVE'] //online exclusive
    const exclusive = attributes && attributes['EXCLUSIVE']
    const avantPremiere = attributes && attributes['AVANT_PREMIERE']
    const limitedEdition = attributes && attributes['LIMITED_EDITION']
    const annualSupplyBadges =
      attributes && attributes['CL_ANNUAL_SUPPLY_BADGES']
    const isNew = attributes && attributes['IS_NEW']
    const badge = attributes && attributes['BADGE']
    const lxSoldOut =
      attributes && (attributes['LX_SOLDOUT'] as ProductSoldOutStatus)
    const modelCodeDisplay = attributes && attributes['MODEL_CODE_DISPLAY']
    const frontColor = attributes && attributes['FRONT_COLOR']
    const lensColor = attributes && attributes['LENS_COLOR']
    const lensColorFacet = attributes && attributes['LENS_COLOR_FACET']
    const templeColor = attributes && attributes['TEMPLE_COLOR']
    const lensTreatment = attributes && attributes['LENS_TREATMENT']
    const lensTreatmentFacet = attributes && attributes['LENS_TREATMENT_FACET']
    const lensMaterialMacroClass =
      attributes && attributes['LENS_MATERIAL_MACRO_CLASS']
    const frameSizeDisplay = attributes && attributes['FRAME_SIZE_DISPLAY']
    const frameSize = attributes && attributes['FRAME_SIZE']
    const brand = attributes && attributes['BRAND']
    const clBrand = attributes && attributes['CL_BRAND']
    const modelName = attributes && attributes['MODEL_NAME']
    const displaySku = attributes && attributes['DISPLAYSKU']
    const bridgeWidth = attributes && attributes['BRIDGE_WIDTH']
    const lensWidth = attributes && attributes['LENS_WIDTH']
    const lensHeight = attributes && attributes['LENS_HEIGHT']
    const templeLength = attributes && attributes['TEMPLE_LENGTH']
    const hingeDistance = attributes && attributes['HINGE_DISTANCE']
    const lxCanonicalCategory =
      attributes && attributes['LX_CANONICAL_CATEGORY']
    const gender = attributes && attributes['GENDER']
    const clVolume = attributes && attributes['CL_VOLUME']
    const clPackSize = attributes && attributes['CL_PACK_SIZE']
    const clIsNewArrival = attributes && attributes['CL_IS_NEWARRIVAL']
    const clIsExclusive = attributes && attributes['CL_IS_EXCLUSIVE']
    const videoAcquisition = attributes && attributes['VIDEO_ACQUISITION']
    const imageAcquisition = attributes && attributes['IMAGE_ACQUISITION']
    const audioSystem = attributes && attributes['AUDIO_SYSTEM']
    const touch = attributes && attributes['TOUCH']
    const voiceControl = attributes && attributes['VOICE_CONTROL']
    const batteryType = attributes && attributes['BATTERY_TYPE']
    const memoryCapacity = attributes && attributes['MEMORY_CAPACITY']
    const connectivity = attributes && attributes['CONNECTIVITY']
    const osCompatibility = attributes && attributes['OS_COMPATIBILITY']
    const chargingCase = attributes && attributes['CHARGING_CASE']
    return {
      lxSearchPageProductType,
      productType,
      roxable,
      frameShape,
      frameMaterialFacet,
      frameMaterialClass,
      frontColor,
      lensColor,
      lensColorFacet,
      lensTreatment,
      lensTreatmentFacet,
      brand,
      modelName,
      displaySku,
      annualSupplyBadges,
      badge,
      isNew,
      limitedEdition,
      webExclusive,
      exclusive,
      avantPremiere,
      sustainabilityClaim,
      customizable,
      templeColor,
      poralized,
      isMostPopular,
      lxSoldOut,
      modelCodeDisplay,
      frontColorFacet,
      templeColorFacet,
      lensMaterialMacroClass,
      frameSizeDisplay,
      frameSize,
      clBrand,
      bridgeWidth,
      lensWidth,
      lensHeight,
      templeLength,
      hingeDistance,
      colorCode,
      lxCanonicalCategory,
      gender,
      clVolume,
      clPackSize,
      clIsNewArrival,
      clIsExclusive,
      videoAcquisition,
      imageAcquisition,
      audioSystem,
      touch,
      voiceControl,
      batteryType,
      memoryCapacity,
      connectivity,
      osCompatibility,
      chargingCase,
    }
  }

  getFormattedPlpFacets = (facets: FacetsAlgolia) => {
    if (!facets) return
    return Object.entries(facets).reduce((acc, [key, value]) => {
      const facetName = key.split('.')[1]
      if (!facetName) return acc
      return {
        ...acc,
        [facetName]: value,
      }
    }, {})
  }

  getHasLxProductTypeOrderItems = (orderItems: OrderItem[]) => {
    const hasFrames = orderItems?.some((item) => {
      const { lxSearchPageProductType } = this.getProductAttributes(
        item.productAttributes
      )
      return this.getLxProductTypes(lxSearchPageProductType).isFrames
    })

    const hasContactLenses = orderItems?.some((item) => {
      const { lxSearchPageProductType } = this.getProductAttributes(
        item.productAttributes
      )
      return this.getLxProductTypes(lxSearchPageProductType).isContactLenses
    })

    const hasOptical = orderItems?.some((item) => {
      const { productType } = this.getProductAttributes(item.productAttributes)
      return this.getLxProductTypes(productType).isOptical
    })

    const hasContactLensesAccessories = orderItems?.some((item) => {
      const { lxSearchPageProductType } = this.getProductAttributes(
        item.productAttributes
      )
      return this.getLxProductTypes(lxSearchPageProductType)
        .isContactLensesAccessories
    })

    return {
      hasFrames,
      hasContactLenses,
      hasOptical,
      hasContactLensesAccessories,
    }
  }

  getSoldOutStatus = (lxSoldOut: ProductSoldOutStatus) => {
    return {
      isComingBackSoon: lxSoldOut === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON,
      isComingSoon: lxSoldOut === PRODUCT_SOLDOUT_STATUS.COMING_SOON,
      isSoldOut: lxSoldOut === PRODUCT_SOLDOUT_STATUS.SOLDOUT,
      isNone: lxSoldOut === PRODUCT_SOLDOUT_STATUS.NONE,
      isInfiniteInventory:
        lxSoldOut === PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY,
    }
  }

  getFormattedServerProductAttributes = (
    attributes: ServerProductAttribute[] = []
  ) => {
    return attributes.reduce((acc, attribute) => {
      return {
        ...acc,
        [attribute.identifier]: attribute?.values?.[0]?.value || '',
      }
    }, {})
  }
}
export default new ProductAlgoliaUtils()
